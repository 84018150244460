import { Stack } from '@chakra-ui/react';
import { HeroImageProps, src } from '@/src/components/UI/HeroImageProps';
import Image from 'next/image';
import Head from 'next/head';
import React, { useMemo, useState } from 'react';
import { Property } from 'csstype';
import ObjectFit = Property.ObjectFit;

const Images: Partial<{ [key in src]: string }> = {
  Home: `${process.env.NEXT_PUBLIC_GOOGLE_CDN_URL}/mobile/largeImages/hero-img-mobile/home_v_4.webp`,
  Sell: `${process.env.NEXT_PUBLIC_GOOGLE_CDN_URL}/mobile/largeImages/hero-img-mobile/hero-sell-mobile_v_2.webp`,
  aboutUS: `${process.env.NEXT_PUBLIC_GOOGLE_CDN_URL}/mobile/largeImages/hero-img-mobile/about_v_6.webp`,
  jobs: `${process.env.NEXT_PUBLIC_GOOGLE_CDN_URL}/mobile/largeImages/hero-img-mobile/hero-careers-mobile-v-2.webp`,
  agent: `${process.env.NEXT_PUBLIC_GOOGLE_CDN_URL}/mobile/largeImages/hero-img-mobile/hero-agents-mobile-v-3.webp`,
  abroad: `${process.env.NEXT_PUBLIC_GOOGLE_CDN_URL}/mobile/largeImages/hero-img-mobile/hero-abroad-mobile_v_2.webp`,
};
const HeroImage = ({ src, alt, text, action, mask = false, bannerSrc }: HeroImageProps) => {
  const [imageSize, setImageSize] = useState<{ naturalWidth: number; naturalHeight: number } | null>(null);

  const objectFit = useMemo<ObjectFit>(() => {
    let objectFit: ObjectFit = 'contain';
    if (imageSize) {
      if (imageSize.naturalWidth > imageSize.naturalHeight) {
        objectFit = 'cover';
      }
    }
    return objectFit;
  }, [imageSize]);

  return (
    <Stack w={'100%'} position={'relative'}>
      <Head>
        <link rel='preload' href={src ? Images[src] : bannerSrc} as='image' />
      </Head>
      {mask && <div className={'absolute top-0 left-0 w-full h-full bg-black z-10 bg-opacity-25'} />}
      <Image
        priority
        src={bannerSrc ? bannerSrc : src ? Images[src]! ?? Images['Home'] : ''}
        objectFit={objectFit}
        className={'bg-gray300'}
        layout={'responsive'}
        width={414}
        height={580}
        alt={alt}
        blurDataURL={
          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNUrAcAAMcAouVPGYoAAAAASUVORK5CYII='
        }
        placeholder={'blur'}
        loading={'eager'}
        onLoadingComplete={setImageSize}
      />
      {text ? (
        <div className={'absolute flex justify-center items-center w-full h-full top-0 z-20'}>
          <p className='text-4xl leading-normal text-center font-medium uppercase text-white'>主導自己的未來</p>
        </div>
      ) : null}
      {action}
    </Stack>
  );
};
export default HeroImage;
